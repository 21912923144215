export default {
	FIREBASE_API_KEY: 'to catch in Browser key',
	FIREBASE_AUTH_DOMAIN:
		'planity-dev2-caee-default-rtdb.europe-west1.firebasedatabase.app',
	FIREBASE_DATABASE_URL:
		'https://planity-dev2-caee-default-rtdb.europe-west1.firebasedatabase.app',
	FIREBASE_AVAILABILITIES_SHARD_URL:
		'https://planity-dev2-caee-availabilities.europe-west1.firebasedatabase.app',
	FIREBASE_AVAILABILITIES_SHARD_1_URL:
		'https://planity-dev2-caee-availabilities-1.europe-west1.firebasedatabase.app',
	FIREBASE_AVAILABILITIES_SHARD_2_URL:
		'https://planity-dev2-caee-availabilities-2.europe-west1.firebasedatabase.app',
	FIREBASE_PUBLIC_SHARD_URL:
		'https://planity-dev2-caee-public.europe-west1.firebasedatabase.app',
	FIREBASE_STORAGE_BUCKET: 'planity-dev2-caee.appspot.com',
	AWS_API_ENDPOINT:
		'https://oa434oee74.execute-api.eu-west-1.amazonaws.com/dev2',
	ALGOLIA_APP_ID: 'T6ITM3JH82',
	ALGOLIA_API_KEY: '0109e5d9e8f506d4bad0409659c480d3',
	ALGOLIA_PRODUCTION_APP_ID: 'DAY79MUBW3',
	ALGOLIA_PRODUCTION_API_KEY: '8ec84cdda274cec79b9ad155973bc864',
	ALGOLIA_PLACES_APP_ID: 'pl574WJIL1Q2',
	ALGOLIA_PLACES_API_KEY: '719fde67c8532799b0f99a4d2b454daa',
	GOOGLE_API_BROWSER_KEY: 'to catch in API key 5',
	HOST: 'dev2.planitydev.com',
	CLOUD_FUNCTIONS_DOMAIN:
		'https://europe-west1-planity-dev2-caee.cloudfunctions.net',
	MAPBOX_API_KEY:
		'pk.eyJ1IjoicGxhbml0eSIsImEiOiI4QU5kRy1jIn0.suHIBfZHkiiPnuVFhQSRMg',
	USER_APP_LINK: 'https://planity.onelink.me/6GTD/4ef6ae2e',
	PARANOID_AVAILABILITIES: ['saco'],
	STRIPE_API_KEY:
		'pk_test_517CtV2A69XfgDO9EgnwM1lmXWCYiaDscjjzSTaosTngmxavETTOmTtJKGdfVt2VgZ6cGBDoY14xYdPBiThKmn619008hNfQCsH',
	STRIPE_API_KEY_FR:
		'pk_test_517CtV2A69XfgDO9EgnwM1lmXWCYiaDscjjzSTaosTngmxavETTOmTtJKGdfVt2VgZ6cGBDoY14xYdPBiThKmn619008hNfQCsH',
	STRIPE_API_KEY_BE:
		'pk_test_51Lfk9VFCwhxbUJ7mSdwaFCrP38BgelUQPvOjK7zbpZGHIvdyVRkfpvreRVHeoees2df6titaLQEjUAtxQfscNPkc00clFCnAsf',
	STRIPE_API_KEY_DE:
		'pk_test_51L15sZE7ePEuIRmRVnUPGutdLBj8udBvUsP86xlxMvdklBgHOSP2E3b57cz9rAP1wtdYCh4vjFazd49CiqUNwxgU0003HcpCuk',
	DISPLAY_REVIEWS: true,
	HIDE_ESHOP: false,
	HIDE_GIFT_VOUCHER_BUTTON: false,
	ENABLE_FACEBOOK_LOGIN: false,
	ENABLE_GOOGLE_LOGIN: false,
	ENABLE_GITHUB_LOGIN: false,
	AVAILABLE_PROVIDERS: ['password'],
	ENABLE_USER_PAYS_FEES: true,
	PLANITY_FEE: '1.58',
	PLANITY_FLAT: '18',
	STRIPE_FEE: '0',
	STRIPE_FLAT: '0',
	ENABLE_CLICK_AND_COLLECT: 'true',
	ENABLE_CLICK_AND_SHOP: true,
	WOOTRIC_ACCOUNT_TOKEN: 'NPS-593dabed',
	WOOTRIC_NO_SURVEYED_COOKIES: false,
	WOOTRIC_SURVEY_IMMEDIATELY: false,
	ENABLE_NPS: false,
	ENABLE_ENRICHMENT: true,
	CATEGORIES_INDEX: 'categories',
	PLACES_INDEX: 'places',
	BUSINESSES_INDEX: 'businesses',
	BUSINESSES_BY_AVERAGE_DESC_INDEX: 'businesses_byAverage_desc',
	BUSINESSES_BY_AVERAGE_ASC_INDEX: 'businesses_byAverage',
	BUSINESSES_BY_RATING_ASC_INDEX: 'businesses_byRating',
	SEO_DATA: 'seo_data_migration',
	RECAPTCHA_SITE_KEY: '6LdvsHQoAAAAAKsRpk4BXBkOZ093mWayM_h8fxZe',
	RECAPTCHA_MACHINE_ARN:
		'arn:aws:states:eu-west-1:962195258732:stateMachine:sendPhoneNumberVerificationCodeRecaptchaProtectedDev2'
};
